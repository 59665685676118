import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import WorkItem from '../components/WorkItem'
import PageTitle from '../components/PageTitle'

const PageWrapper = styled.div`
  padding: 2rem 2rem 3rem var(--pad4);
  max-width: 55rem;
  display: flex;
  flex-flow: row wrap;
`

const TodayHeader = styled.header`
  font-family: sans-serif;
  padding: 2em;
  padding-top: 6em;
  --perspectiveTransform: rotateX(25deg) rotateY(25deg);
  border: solid 1px var(--gray-4);
  transform: var(--perspectiveTransform);
  margin-top: -4em;
  margin-left: -4em;
  max-width: 20em;
  position: relative;
`

const SiteTitle = styled.p`
  margin: 0;
  color: var(--gray-4);
  text-align: left;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  padding-left: 2em;
`

const Tagline = styled.p`
  margin: 0;
  margin-top: 0.75em;

  text-transform: uppercase;
  text-align: right;
  letter-spacing: 0.03em;
  line-height: 1;
  .tagline__link {
    font-size: 1.3em;
    font-weight: 400;
    text-transform: initial;
  }
  .tagline__text {
    font-size: 0.9em;
    font-weight: 700;
    color: var(--primeGray);
    padding-right: 0.5em;
  }
`

const ContentWrapper = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: dense;
`

const Latest = styled.article`
  display: block;
  background: var(--gray-6);
  grid-column: 1;
  height: 10em;
  width: 100%;
`

const Featured = styled.article`
  display: block;
  grid-row: span 3;
  grid-column: span 2;
  background: var(--primeColor);
  height: 100%;
  width: 100%;
`

const Category = styled.article`
  display: block;
  background: var(--offPrimeGray);
  height: 10em;
  width: 100%;
`

class Today extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const image = data.image

    return (
      <ContentWrapper>
        <TodayHeader>
          <SiteTitle className="title">
            <span data-content={'Miles Elliott'}>
              Miles.Today
              <br />
              Elliott
            </span>
          </SiteTitle>
          <Tagline>
            <span className="tagline__text">Content by</span>
            <a className="tagline__link" noopener href="http://miles.work">
              Miles Elliott
            </a>
          </Tagline>
        </TodayHeader>
        <Latest>Hello World</Latest>
        <Latest>Hello World</Latest>
        <Featured>Hello World</Featured>
        <Category>Hello World</Category>
        <Category>Hello World</Category>
        <Category>Hello World</Category>
      </ContentWrapper>
    )
  }
}

export default Today

export const pageQuery = graphql`
  query {
    image: file(absolutePath: { regex: "/miles_elliott.jpg/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
